<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <AddCompanyGroupDlg ref="addCompanyGroupDlg" v-on:Success="LoadData"></AddCompanyGroupDlg>
        <h2 class="card-title d-flex">{{$t('COMPANY_GROUP.HEADER')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('COMPANY_GROUP.HEADER.TEXT')}}</span>
        </p>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card >
        <can I="create" a="companygroup">
          <div class="d-flex align-items-center">
              <vs-button color="primary" type="filled" @click="AddGroup" icon="supervisor_account">{{$t('COMPANY_GROUP.BUTTON.ADD_GROUP')}}</vs-button>
           </div> 
        </can>          
        <div id="companygroup_table" class="vs-con-loading__container" >
          <vs-table
            search
            :data="groups"
            :noDataText="$t('COMPANY_GROUP.NO_DATA')"
            stripe
            max-items="25" 
            pagination
            hoverFlat>

            <template slot="header">
              <h3>
                {{$t('COMPANY_GROUP.TABLE.HEADER')}}
              </h3>
            </template>
            <template slot="thead">                               
              <vs-th sort-key="name">
                {{$t('COMPANY_GROUP.TABLE.COL1')}}
              </vs-th>
              <vs-th sort-key="companyGroupType">
                {{$t('COMPANY_GROUP.TABLE.COL3')}}
              </vs-th>                                                                                                          
              <vs-th></vs-th>                                                                                                                                            
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >   
                <vs-td :data="tr.name">
                  {{tr.name}}
                </vs-td>  
                <vs-td :data="tr.companyGroupType">
                  {{GetCompanyGroupTypeText(tr.companyGroupType)}}
                </vs-td>   
                <vs-td>
                  <div>
                    <CompanyGroupMemberSelect ref="companyGroupMemberSel" :CompanyGroupFk="tr.id" :Member="tr.member" v-on:Success="LoadData"></CompanyGroupMemberSelect>
                  </div>
                </vs-td>                                                                                                            
                <vs-td>
                  <vs-button  @click="EditCompanyGroupRights(tr.id)" class="mr-2" size="small" color="primary" type="filled" icon="open_in_new"></vs-button> 
                  <vs-button  @click="EditCompanyGroup(tr)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                  <vs-button  @click="OpenConfirmDeleteCompanyGroup(tr.id,tr.name)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                </vs-td>                    
                                                                                                                                                                                          
              </vs-tr>
            </template>
          </vs-table>
          <vs-popup fullscreen :title="$t('COMPANY_GROUP.GROUP_RIGHT_TABLE_POPUP')" :active.sync="popupActive">
             
            <GroupRightsTable :isCompanyGroup="true" ref="groupRightsTable"></GroupRightsTable>
          </vs-popup>
        </div> 
      </vs-card>
    </vs-col>
  </vs-row>
</div>
</template>
<script>
import enumHelper from '../helper/enumHelper';
import AddCompanyGroupDlg from './components/dialogs/AddCompanyGroupDlg.vue';
import GroupRightsTable from './components/group/GroupRightsTable.vue';
import CompanyGroupMemberSelect from './components/group/CompanyGroupMemberSelect.vue';
import helperMethods from '../helper/staticFuncHelper';
import codeHelper from '../helper/staticCodeHelper';

export default {
  name: "CompanyGroupAdmin",
  components: {
    AddCompanyGroupDlg,
    GroupRightsTable,
    CompanyGroupMemberSelect
  },
  data: function (){
      return {
      groups:[],
      selectedCompanies:[],
      possibleMember:[],
      popupActive:false
      };
  },
  mounted () {
        this.LoadData();
    },
  computed: 
  {
    getTableData() {

        var data = [];

        if(this.$store.state.companygroup.companygroups.data != null)
        {
          data = this.$store.state.companygroup.companygroups.data;
        }

        return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.companygroup.companygroups.status) != 'undefined')
        loading = this.$store.state.companygroup.companygroups.status.loading;
      return loading;
    },
  },
  watch: {
      getTableData(value) {
          this.$data.groups = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#companygroup_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#companygroup_table'});
        }
      }
    },
  methods: {
      ...enumHelper,
      ...helperMethods,
      ...codeHelper,
      LoadData: function ()
      {
        this.$store.dispatch('companygroup/getCompanyGroups', { root: true },{ root: true });
        if(this.IsNotNull(this.$refs.companyGroupMemberSel) && !this.IsEmpty(this.$refs.companyGroupMemberSel))
        {
          this.$refs.companyGroupMemberSel.forEach(element => {
            element.RefreshData();
          });
        }
      },
      AddGroup: function (){
        this.$refs.addCompanyGroupDlg.ShowDlg();
      }, 
      EditCompanyGroup(data){
        this.$refs.addCompanyGroupDlg.InitDlg(data);
      },
      EditCompanyGroupRights(id){
        this.popupActive = true;
        this.$refs.groupRightsTable.LoadGroupRights(id);
      },
      
    OpenConfirmDeleteCompanyGroup(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('COMPANY_GROUP.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('companygroup/deleteCompanyGroup', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COMPANY_GROUP.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('COMPANY_GROUP.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COMPANY_GROUP.ERROR.DELETE').replace("*1*",error)});  
        });
      }
  }
};

</script>