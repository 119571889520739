<template>
  <div>
    <div id="companygrouprights_table" class="vs-con-loading__container" >
        <vs-table
        search
        :data="rights"
        :noDataText="$t('COMPANY_GROUP.RIGHTS_TABLE.NO_DATA')"
        stripe
        max-items="100" 
        pagination
        hoverFlat>

        <template slot="header">
            <h3>{{$t('COMPANY_GROUP.RIGHTS_TABLE.HEADER')}}</h3>
        </template>                                                                                                                                 

        <template slot="thead">                               
            <vs-th>{{$t('COMPANY_GROUP.RIGHTS_TABLE.SECTION')}}</vs-th>                                                                                                                                             
            <template v-for="(right_h,idx_h) in getHeader">                                                                                                                                  
                <vs-th :key="'header_'+idx_h">{{$t('COMPANY_GROUP.RIGHTS_TABLE.COL_'+right_h.action.toUpperCase())}}</vs-th>                                                                                                                                             
            </template>                                                                                                                                            
        </template>

        <template slot-scope="{data}">
            <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >   
                <vs-td :data="tr.sectionname">
                    {{$t('COMPANY_GROUP.RIGHTS_TABLE.ROW_'+tr.sectionname.toUpperCase())}}
                </vs-td> 
                <template v-for="(right,idx) in tr.sectionRights">
                    <vs-td :key="'right_'+idx" :data="right.action">
                    <vs-checkbox v-show="right.available" v-model="right.selected"></vs-checkbox>
                    <vs-icon v-show="!right.available" icon="block" color="gray"></vs-icon>
                    </vs-td> 
                </template>                                                                                                                                                                                      
            </vs-tr>
        </template>
        </vs-table>
    </div>
    <div  class="btn-alignment">
    <vs-button @click="SelectAll">{{$t('COM.ALL')}}</vs-button>
    <vs-button @click="SelectNone">{{$t('COM.NONE')}}</vs-button>
    <vs-button @click="InvertAll">{{$t('COM.INVERT')}}</vs-button>
    </div>
  </div>
</template>
<script>
import enumHelper from '../../../helper/enumHelper';
export default {
  name: "GroupRightsTable",
  props:['isCompanyGroup'],
   data: function (){
     return {
        rights:[],
        loaded:false,
        grpId:0,
     }
  },
  computed:{
    getHeader(){
      var retVal = [];
      if(this.rights.length > 0)
      {
        retVal = this.rights[0].sectionRights;
      }
      return retVal;
    },
    getTableData() {

        var data = [];
        if(this.isCompanyGroup == true)
        {
          if(this.$store.state.right.companygrouprights.data != null)
          {
            data = this.$store.state.right.companygrouprights.data.sections;
          }
        }
        else
        {
          if(this.$store.state.right.grouprights.data != null)
          {
            data = this.$store.state.right.grouprights.data.sections;
          }
        }
        return data;
    },
    loadingTableData () {
      var loading = false;
      if(this.isCompanyGroup == true)
      {
        if(typeof(this.$store.state.right.companygrouprights.status) != 'undefined')
        {
          loading = this.$store.state.right.companygrouprights.status.loading;
        }
      }
      else
      {
        if(typeof(this.$store.state.right.grouprights.status) != 'undefined')
        {
          loading = this.$store.state.right.grouprights.status.loading;
        }
      }
      return loading;
    },
    selectedRightFks(){
      var rightfks = [];
       this.rights.forEach(right => {
        right.sectionRights.forEach(sectionright => {
          if(sectionright.available == true)
          {
            if(sectionright.selected == true)
            {
              rightfks.push(sectionright.right_fk);
            }
          }
          });
      });
      return rightfks;
    }
  },
  methods:{
    ...enumHelper,
    LoadGroupRights(id)
    {
        this.loaded = false;
        this.rights = [];
        if(this.isCompanyGroup == true)
        {
          this.$store.dispatch('right/getRightsForCompanyGroup', id);
        }
        else
        {
          this.$store.dispatch('right/getRightsForGroup', id);
        }
        this.grpId = id;
    },
    SelectAll(){
      this.rights.forEach(right => {
        right.sectionRights.forEach(sectionright => {
          if(sectionright.available == true)
          {
            sectionright.selected = true;
          }
          });
      });
    },
    SelectNone(){
      this.rights.forEach(right => {
        right.sectionRights.forEach(sectionright => {
          if(sectionright.available == true)
          {
            sectionright.selected = false;
          }
          });
      });
    },
    InvertAll(){
      this.rights.forEach(right => {
        right.sectionRights.forEach(sectionright => {
          if(sectionright.available == true)
          {
            sectionright.selected = !sectionright.selected;
          }
          });
      });
    },
    SaveChanges(selectedRights)
    {
      if(this.isCompanyGroup == true)
      {
        this.$store.dispatch('right/updateRightsForCompanyGroup', {companygroup_fk:this.grpId, right_fks:selectedRights})
         .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COMPANY_GROUP.RIGHTS_TABLE.SUCCESS.SAVE')});  
            }
            else
            {
              var text = this.$t('COMPANY_GROUP.RIGHTS_TABLE.WARNING.SAVE').replace("*1*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            
          }, 
          error =>{
              var text = this.$t('COMPANY_GROUP.RIGHTS_TABLE.ERROR.SAVE').replace("*1*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          });
      }
      else
      {
        this.$store.dispatch('right/updateRightsForGroup', {group_fk:this.grpId, right_fks:selectedRights})
        .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COMPANY_GROUP.RIGHTS_TABLE.SUCCESS.SAVE')});  
            }
            else
            {
              var text = this.$t('COMPANY_GROUP.RIGHTS_TABLE.WARNING.SAVE').replace("*1*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            
          }, 
          error =>{
              var text = this.$t('COMPANY_GROUP.RIGHTS_TABLE.ERROR.SAVE').replace("*1*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          });
      }
    }
  },
  watch:{
      getTableData(value) {

          this.rights = value;

      },
      // eslint-disable-next-line no-unused-vars
      loadingTableData(value)
      {
        /* if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#companygrouprights_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#companygrouprights_table'});
        } */
      },
      selectedRightFks(value)
      {
        if(this.loaded == true)
        {
          this.SaveChanges(value);
        }
        if(this.loadingTableData == false)
        {
          this.loaded = true;
        }
      }

  }
};
</script>