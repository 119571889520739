<template>
  <div class="d-flex">
    <vs-select v-show="showSelect"
      multiple
      v-model="selectedCompanies">
      <vs-select-item class="selectUserItem" :key="index" :value="item.value" :text="item.text" v-for="(item,index) in possibleMember" />
    </vs-select>
    <span v-show="!showSelect">{{$t('COMPANY_GROUP.NO_COMPANIES')}}</span>
    <vs-button v-show="showSelect" :disabled="!changed" class="ml-1" size="small" icon="save" @click="SaveMember"></vs-button>
  </div>
</template>
<script>

export default {
  name: "CompanyGroupMemberSelect",
  props:['CompanyGroupFk', 'Member'],
   data: function (){
     return {
      selectedCompanies:[],
      possibleMember:[],
      showSelect:true,
      selectedOld:"",
      changed:false
     }
  },
  mounted () {
      this.GetPossibleMember(this.CompanyGroupFk);
  },
  computed:{
    
  },
  methods:{
    RefreshData(){
      this.GetPossibleMember(this.CompanyGroupFk);
    },
    SaveMember(){

      var data = {companygroup_fk:this.CompanyGroupFk,member:this.selectedCompanies};

      this.$store.dispatch('companygroup/updateCompanyGroupMember', data)
        .then(response => {
          if(response.success)
          {
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COMPANY_GROUP.MEMBER.SUCCESS.UPDATE')}); 
            this.$emit("Success");
          }
          else
          {
            var text = this.$t('COMPANY_GROUP.MEMBER.WARNING.UPDATE').replace("*1*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COMPANY_GROUP.MEMBER.ERROR.UPDATE').replace("*1*",error)});  
            this.showSelect = false;
        });
    },
    GetPossibleMember(id){
      this.possibleMember = [];
      this.$store.dispatch('companygroup/getPossibleMember', id)
        .then(response => {
          if(response.length > 0)
          {
            this.showSelect = true;
             response.forEach(mem => {
               var entry = {text:mem.name, value:mem.id} ;
                this.possibleMember.push(entry);
                this.Member.forEach(selMem => {
                  if(selMem.company_fk == mem.id)
                  {
                    this.selectedCompanies.push(mem.id);
                  }
                });
             });
             this.selectedOld = JSON.stringify(this.selectedCompanies);
          }else
          {
            this.showSelect = false;
          }
        }, 
        // eslint-disable-next-line no-unused-vars
        error =>{ 
            this.showSelect = false;
        });
    }
   
  },
  watch:{
    selectedCompanies(value){
      this.changed = this.selectedOld != JSON.stringify(value);
    }     

  }
};
</script>
<style lang="css">
.selectUserItem > button > span{
    margin-left:7px;
}
</style>