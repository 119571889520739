<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.OK')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddCompanyGroup"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('COMPANY_GROUP.ADD_COMPANY_GROUP.HEADER.TEXT')}}</p>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('COMPANY_GROUP.ADD_COMPANY_GROUP.LABEL.NAME')"  class="float-left mr-2 inputx" :placeholder="$t('COMPANY_GROUP.ADD_COMPANY_GROUP.LABEL.PLACEHOLDER.NAME')"  v-model="name"  :danger="name_fail" val-icon-danger="clear" />   
          </div>
          <div class="default-input d-flex align-items-center">
            <vs-select :label="$t('COMPANY_GROUP.ADD_COMPANY_GROUP.LABEL.GROUP_TYPE')" v-model="companyGroupType">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in companyGroupStatesForSelect" />
            </vs-select>
          </div>
          <div class="default-input d-flex align-items-center mt-3">
            <vs-checkbox v-model="nur_besteller">{{$t('COMPANY_GROUP.ADD_COMPANY_GROUP.LABEL.BESTELLER')}}</vs-checkbox>
          </div>
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';

export default {
  name: "AddCompanyGroupDlg",
  props:['Type'],
  components:{
    },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('COMPANY_GROUP.ADD_COMPANY_GROUP.TITLE'),
      id:0,
      name:"",
      companyGroupType:0,
      name_fail:false,
      name_ok:false,
      member:[],
      groupRights:[],
      nur_besteller:false

    }
  }, 
  mounted () {
    
    this.ClearForm();
    
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      
      ShowDlg(){
        this.ClearForm();
        this.dlgActive=true;
      },
      InitDlg(data)
      {
        this.ClearForm();
        this.id = data.id;
        this.name = data.name;
        this.companyGroupType = data.companyGroupType;
        this.member = data.member;
        this.groupRights = data.groupRights;
        this.popupTitle = this.$t('COMPANY_GROUP.EDIT_COMPANY_GROUP.TITLE');
        this.dlgActive=true;
        this.nur_besteller=data.nur_besteller;
      },
      closed(){
        this.ClearForm();
      },
      accepted(){

          var data = {
            id:this.id,
            name: this.name,
            companyGroupType: this.companyGroupType,
            member:[],
            groupRights:[],
            nur_besteller: this.nur_besteller
          };

          this.member.forEach(mem => {
            data.member.push(mem);
          });

          this.groupRights.forEach(mem => {
            data.groupRights.push(mem);
          });

          this.$store.dispatch('companygroup/saveCompanyGroup', data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COMPANY_GROUP.ADD_COMPANY_GROUP.SUCCESS.SAVE')});  
                this.$emit('Success',response.id);
            }
            else
            {
              var text = this.$t('COMPANY_GROUP.ADD_COMPANY_GROUP.WARNING.SAVE');
              text = text.replace("*1*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COMPANY_GROUP.ADD_COMPANY_GROUP.ERROR.SAVE') + error});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.popupTitle = this.$t('COMPANY_GROUP.ADD_COMPANY_GROUP.TITLE');
        this.id = 0;
        this.name = "";
        this.companyGroupType = 0;
        this.name_fail = false;
        this.name_ok = false;
        this.member = [],
        this.groupRights =[]
        this.nur_besteller = false;
      },
  },
  computed:{
    formValid(){
      return this.name_ok;
    },
    companyGroupStatesForSelect(){
      return this.GetCompanyGroupTypeForDropDown();
    }
  },
  watch:{
    name(value)
    {
        this.name_fail = value.length == 0;
        this.name_ok = !this.name_fail;
    },
  }
};
</script>
<style>
div#promptAddCompanyGroup > div.vs-dialog{
  max-width: 500px !important;
}
</style>